import React from "react";
import "../styles/index.sass";
import Footer from "../components/Footer";
import CommitmentCard from "../components/CommitmentCard";
import { title } from "process";
import Carousel from "../components/Carousel";
import Spacer from "../components/Spacer";
import Slider from "../components/ProgressBar";
import BrandCard from "../components/BrandCard";
import useIsMobileViewport from "../hooks/useIsMobileViewport";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const StylesPage = () => {
    const { isMobile } = useIsMobileViewport();
    return (
        <main>
            <h1 className='text-xl'>hello world</h1>
            <h1 className='text-xl font-condensed'>hello world</h1>
            <h1 className='text-xl font-condensed font-bold'>hello world</h1>
            <h1 className='text-3xl text-gold font-bold'>
                ELECTRIC GIBSON COLLECTIONS
            </h1>
            <h1 className='text-3xl text-gold font-bold font-condensed'>
                ELECTRIC GIBSON COLLECTIONS
            </h1>
            <br />
            <button className='btn btn-gold'>A BUTTON</button>
            <button className='btn btn-black'>A BUTTON</button>
            <Spacer height='50px' />
            {/* <CommitmentCard secondaryCards={[{}, {}]} /> */}
            <Spacer height='50px' />

            <Carousel hasArrows isVertical={isMobile}>
                {/* <BrandCard />
                <BrandCard />
                <BrandCard />
                <BrandCard />
                <BrandCard />
                <BrandCard /> */}
            </Carousel>

            <Spacer height='50px' />
            <Footer />
        </main>
    );
};

export default StylesPage;